<template>
  <div>
    <b-field label="Name">
      <b-input v-model="form.name"></b-input>
    </b-field>
    <label for="dropzone">Logo</label>
    <vue-dropzone
      ref="logoDropzone"
      id="dropzone"
      @vdropzone-mounted="addFile"
      :options="dropzoneOptions"
    >
    </vue-dropzone>
    <b-button type="is-info" @click="send" icon-left="save">
      Save
    </b-button>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
export default {
  name: "TeamForm",
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    errors: {
      type: Object,
      default() {
        return {}
      },
    },
    initial: {
      type: Object,
      default() {
        return {
          id: null,
          name: "",
          logo: "",
        }
      },
    },
  },
  data() {
    let url = process.env.VUE_APP_API + "/teams/"
    let method = "post"
    if (this.initial.id) {
      url += this.initial.id + "/"
      method = "put"
    }
    return {
      form: { ...this.initial },
      dropzoneOptions: {
        url: url,
        method: method,
        thumbnailWidth: 150,
        headers: {
          Authorization: "Bearer " + this.$store.getters["auth/user"].access,
        },
        maxFiles: 1,
        addRemoveLinks: true,
        autoProcessQueue: false,
        paramName: "logo",
      },
    }
  },
  methods: {
    send() {
      let formData = new FormData()
      if (this.$refs.logoDropzone.getAcceptedFiles().length > 0) {
        formData.append("logo", this.$refs.logoDropzone.getAcceptedFiles()[0])
      }
      formData.append("name", this.form.name)
      this.$emit("submitted", formData)
    },
    reset() {
      this.form.name = ""
      this.$refs.logoDropzone.removeAllFiles()
    },
    addFile() {
      if (this.form.logo) {
        const file = { size: 123, name: "Icon", type: "image/png" }
        const url = this.form.logo
        this.$refs.logoDropzone.manuallyAddFile(file, url)
      }
    },
  },
}
</script>
