<template>
    <div class="center" v-if="this.tag_metrics.length > 0" >
        <h4 class="is-size-4">{{ this.segment_names[this.tag] }}</h4>
        <div class="chart-container">
            <apexchart :options="chartOptions" :series="chartSeries">
            </apexchart>
        </div>
        
        <div class="select">
        <select v-model="metric">
            <option v-for="metric in metrics" v-bind:key="metric.value" v-bind:value="metric.value">
                {{ metric.text }}
            </option>>
        </select>
        </div>
        <br />
        <p>{{this.metric_text}} explains {{r2Score}} of the variation in maneuver loss for {{this.segment_names[this.tag].toLowerCase()}}.</p>
        <p v-if="this.correlated">
            As {{this.metric_text}} increases, maneuver loss {{this.regDirection}}.
        </p> 
        <br v-else />
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {linearRegression, linearRegressionLine, rSquared} from 'simple-statistics'

export default {
  name: "LossChart",
  props: ["track", "tag"],
  components: {
    'apexchart': VueApexCharts,
  },
  data() {
      let corr_metrics = [
            { text: 'Min Speed', value: 'min_speed' },
            { text: 'Max Speed', value: 'max_speed' },
            { text: 'Entry Speed', value: 'entry_speed' },
            { text: 'Exit Speed', value: 'exit_speed' },
            { text: 'Min Turn Rate', value: 'min_turn_rate' },
            { text: 'Max Turn Rate', value: 'max_turn_rate' },
            { text: 'Avg Turn Rate', value: 'avg_turn_rate' },
            { text: 'Maneuver Angle', value: 'maneuver_angle' },
        ]

      return {
        metric: "min_speed",
        corr_metrics,
        segment_names: {
            T: "Tacks",
            G: "Gybes",
            B: "Bear Aways",
            D: "Douses",
        },
      }
  },
  computed: {
    tag_metrics() {
        let tag_metrics = this.track.metrics.filter((metric) => metric.label === this.tag)
        return tag_metrics
    },
    metrics() {
        let first_metric = this.tag_metrics[0]
        // only include metrics if there value is not null
        let metrics = this.corr_metrics.filter(metric => first_metric[metric.value] !== null)
        return metrics
    },
    points() {
        let points = this.tag_metrics.map((seg_metrics) => {
            let metric_value = seg_metrics[this.metric]
            return [metric_value, seg_metrics.loss_80]
        })
        return points
    },
    regObj() {
        return linearRegression(this.points)
    },
    regLine() {
        let regLine = linearRegressionLine(this.regObj)
        return regLine
    },
    r2Score() {
        let r2 = rSquared(this.points, this.regLine)
        let r2_percent = r2 * 100
        return r2_percent.toFixed(2) + "%"
    },
    regDirection() {
        if (this.regObj.m > 0) {
            return "increases"
        } else {
            return "decreases"
        }
    },
    correlated() {
        let r2 = rSquared(this.points, this.regLine)
        return r2*100 >= 1
    },
    chartSeries() {
        let minPoint = this.points.reduce((min, p) => p[0] < min ? p[0] : min, this.points[0][0])
        let maxPoint = this.points.reduce((max, p) => p[0] > max ? p[0] : max, this.points[0][0])
        let lineData = [
                [minPoint, this.regLine(minPoint)],
                [maxPoint, this.regLine(maxPoint)]
            ]
        let series = [
            {
                name: "Maneuver Loss",
                type: "scatter",
                data: this.points
            },
            {
                name: "Correlation",
                type: "line",
                data: lineData,
            }
        ]
        return series
    },
    metric_text() {
        let metric_obj = this.metrics.find(metric => {
            return metric.value === this.metric
        })
        return metric_obj.text
    },
    chartOptions() {
        return {
            xaxis: {
                type: 'numeric',
            },
            yaxis: {
                tickAmount: 1,
                title: {
                    text: "Maneuver Loss",
                    style: {
                        color: "#000"
                    }
                },
                labels: {
                    formatter: val => val.toFixed(2)
                }
            },
            markers: {
                size: [6, 0]
            },
            stroke: {
                width: 2,
            },
            legend: {
                show: false,
            },
            chart: {
                type: 'line',
                zoom: {
                    enabled: false,
                }
            },
            tooltip: {
                enabledOnSeries: [0],
                shared: false,
                x: {
                    formatter: (val) => `${this.metric_text}: ${val}`
                },
                y: {
                    formatter: (val) => val.toFixed(2)
                }
            }
        }
    }

  },
}
</script>

<style>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.chart-container {
    width: 100%;
}

</style>
