<template>
  <layout>
    <div class="container is-fluid full-height">
      <div v-if="track">
        <div class="spinner-container" v-if="track_id != track.id">
          <p><i class="fa fa-spinner fa-spin"></i></p>
        </div>
        <div v-else >
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h1 class="is-size-3">Track Report</h1>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <h1 class="title is-1">{{track_id}}</h1>
              </div>
            </div>
          </div>
          <h4 class="is-size-4">{{ track.full_name }}</h4>
          <p>
            {{ track.start_time | moment("M/D/YYYY") }}<br />
            {{track.location}}
          </p>
          <div class="columns" >
              <div class="column">
              <dl v-if="this.track.metric">
                  <dt>DISTANCE </dt>
                  <dd>{{ distance }}mi</dd>
                  <dt>TWA</dt>
                  <dd>{{ track.metric.average_wind_direction }}</dd>
                  <dt>DURATION</dt>
                  <dd>{{ duration }}</dd>
                  <dt>MAX SPEED</dt>
                  <dd>{{ track.metric.max_speed }}</dd>
              </dl>
              </div>
              <div class="column">
                <dl>
                  <dt>TACKS</dt>
                  <dd>{{ track.metric.tacks }}</dd>
                  <dt>SETS</dt>
                  <dd>{{ track.metric.sets }}</dd>
                  <dt>GYBES</dt>
                  <dd>{{ track.metric.gybes }}</dd>
                  <dt>DOUSES</dt>
                  <dd>{{ track.metric.douses }}</dd>
                </dl>
              </div>
          </div>

          <div v-if="track.metrics.length > 0" class="columns" >
            <div class="column">
              <LossChart class="block" :track="track" tag="T"/>
              <dl v-if="has_maneuver_angle" class="block">
                  <dt>AVG TACKING ANGLE </dt>
                  <dd>{{ this.avg_tacking_angle }} °</dd>
                  <dt>AVG TURN RATE</dt>
                  <dd>{{ this.avg_tack_rate }} °/s</dd>
              </dl>
              <LossChart :track="track" tag="B"/>
            </div>
            <div class="column">
              <LossChart class="block" :track="track" tag="G"/>
              <dl v-if="has_maneuver_angle" class="block">
                  <dt>AVG GYBING ANGLE </dt>
                  <dd>{{ this.avg_gybing_angle }} °</dd>
                  <dt>AVG TURN RATE</dt>
                  <dd>{{ this.avg_gybe_rate }} °/s</dd>
              </dl>
              <LossChart :track="track" tag="D"/>
            </div>
          </div>
        </div>
      </div>
      <div class="spinner-container" v-else>
          <p><i class="fa fa-spinner fa-spin"></i></p>
      </div>
    </div>
  </layout>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import layout from "@/views/layouts/Default.vue"
import { mapActions, mapState } from "vuex"
import LossChart from './widgets/LossChart.vue'

export default {
  name: "TrackReport",
  props: ["track_id"],
  components: {
    layout,
    'apexchart': VueApexCharts,
    LossChart,
  },
  created() {
    this.fetchTrack(this.track_id)
    this.user = this.$store.getters["auth/user"]
  },
  beforeDestroy() {
    this.removeTrack()
  },
  data: function () {
    return {
        trackId: 1,
    }
  },
  computed: {
    ...mapState({
        track(state) {
            return state.tracks.track
        },
    }),
    has_maneuver_angle() {
      if(this.track.metrics.length === 0) {
        return false
      }
      return this.track.metrics[0].maneuver_angle !== null
    },
    avg_tack_rate() {
      return this.avg_turn_rate("T")
    },
    avg_gybe_rate() {
      return this.avg_turn_rate("G")
    },
    avg_tacking_angle() {
      return this.avg_maneuver_angle("T")
    },
    avg_gybing_angle() {
      return this.avg_maneuver_angle("G")
    },
    distance() {
      return this.track.metric.distance
        ? (this.track.metric.distance * 0.0006213712).toFixed(2)
        : 0
    },
    duration() {
      if (this.track.metric.duration) {
        let wallTime = new Date(null)
        wallTime.setSeconds(this.track.metric.duration)
        return wallTime.toISOString().substr(11, 8)
      } else {
        return "0:00"
      }
    },

    chartOptions() {
        return {
            xaxis: {
                tickAmount: 1,
            },
            yaxis: {
                tickAmount: 1,
                title: {
                    text: "Maneuver Loss",
                    style: {
                        color: "#000"
                    }
                },
            },
            markers: {
                size: [6]
            },
            chart: {
                type: 'scatter',
                zoom: {
                    enabled: false,
                }
            }
        }
    }

  },
  methods: {
    ...mapActions("tracks", ["fetchTrack", "removeTrack"]),
    avg_maneuver_angle(tag) {
      let maneuver_metrics = this.track.metrics.filter((metric) => metric.label === tag)
      let angles = maneuver_metrics.map(metric => metric.maneuver_angle)
      let avg_angle = angles.reduce((a,b) => a+b, 0) / angles.length
      return avg_angle.toFixed(2)
    },
    avg_turn_rate(tag) {
      let maneuver_metrics = this.track.metrics.filter((metric) => metric.label === tag)
      let rates = maneuver_metrics.map(metric => metric.avg_turn_rate)
      let avg_rate = rates.reduce((a,b) => a+b, 0) / rates.length
      return avg_rate.toFixed(2)
    },
  },
}
</script>

<style>
.spinner-container {
  width: 100vw;
  height: 500px;
  display: grid;
  place-items: center;
}

</style>
