<template>
  <div>
    <div class="container">
      <h1 class="is-size-3">My Teams</h1>
      <b-table :data="teams" class="big-table">
          <b-table-column field="logo" label="" width="100" v-slot="props">
            <img :src="props.row.logo" class="team-logo" />
          </b-table-column>
          <b-table-column field="name" label="Name" logo="Name" v-slot="props">
            <router-link
              class="navigation-link"
              :to="`/teams/${props.row.id}`"
              >{{ props.row.name }}</router-link
            >
          </b-table-column>
          <b-table-column field="coaches" label="Coaches" v-slot="props">
            <span
              v-for="(coach, index) in props.row.coaches"
              v-bind:key="coach.id"
            >
              {{ coach.first_name }} {{ coach.last_name
              }}<span v-if="index + 1 < props.row.coaches.length">,</span>
            </span>
          </b-table-column>
          <b-table-column field="athletes" label="Athletes" :numeric="true" v-slot="props">
            {{ props.row.athletes.length }}
          </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                You aren't a member of any teams. You can create a new team with
                the form on the right.
              </p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import Card from "@/components/ui/Card.vue"
export default {
  name: "TeamList",
  components: { Card },
  data() {
    return {}
  },
  beforeCreate() {
    this.$store.dispatch("teams/fetchTeams")
    this.$store.dispatch("teams/clearTeam")
  },
  computed: {
    ...mapGetters({
      teams: "teams/getTeams",
    }),
  },
}
</script>
<style scoped>
.team-logo {
  max-height: 100%;
  max-width: 100%;
}
</style>
