import axios from "axios"

export default {
  getWindFiles() {
    return axios.get(`/windfiles/`)
  },
  getWindFile(id) {
    return axios.get(`/windfiles/${id}/`)
  },
  attachWindFile(trackId, windfileId) {
    return axios.post(`/windfiles/${windfileId}/attach/`, {track_id: trackId})
  },
  createWindReport(windFileId, name, startDateStr, endDateStr) {
    return axios.post(`/windreports/`, {
      wind_file: windFileId,
      name: name,
      start_time: startDateStr,
      end_time: endDateStr
    })
  },
  getWindReport(windFileId) {
    return axios.get(`/windreports/${windFileId}/`)
  },
}