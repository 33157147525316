<template>
  <layout>
    <div class="container">
      <div class="columns">
        <div class="column">
          <TeamList></TeamList>
        </div>
        <div class="column is-one-quarter" v-if="user.enabled_features['coach_tools']">
          <CreateTeam></CreateTeam>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import layout from "@/views/layouts/Default"
import TeamList from "@/views/teams/TeamList.vue"
import CreateTeam from "@/views/teams/CreateTeam.vue"
import { mapGetters } from "vuex"
export default {
  name: "TeamIndex",
  components: { layout, TeamList, CreateTeam },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
}
</script>
