<template>
  <div class="windchart mt-5">
    <h1 class="is-size-4" v-if="this.tag === 'T'">Expected Tacking Loss</h1>
    <h1 class="is-size-4" v-if="this.tag === 'G'">Expected Gybing Loss</h1>
    <apexchart v-if="this.loss" type="line" :options="options" :series="series">
    </apexchart>
    <i>r: {{ rline.value }}</i>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
export default {
  name: 'ExpectedLoss',
  props: ['start', 'end', 'tag', 'boatclass', 'user'],
  components: {
    'apexchart': VueApexCharts,
  },
  data(){
    return {
      loss: {},
      regression: [],
      rline: ''
    }
  },
  mounted(){
    this.getMetrics()
  },
  methods:{
    getMetrics(){
      const url = `/reports/expectedloss/?start=${this.start}&end=${this.end}&tag=${this.tag}&class=${this.boatclass}&user=${this.user}`
      axios.get(url).then(response => {
        this.loss = response.data.losses
        this.regression = response.data.regression
        this.rline = response.data.rline
      })
    },
  },
  computed:{
    options(){
      return {
        yaxis: {
          decimalsInFloat: 2,
          title: {
            text: 'Manuever Loss (Meters)'
          }
        },
        stroke: {
          curve: ['straight', this.rline.type ],
          dashArray: [1, 2],
          width: [5, 2]
        },
        markers: {
          size: [5, 0],
          showNullDataPoints: false,
        },
        tooltip: {
          shared: false,
          intersect: true,
        },
        legend: {
          show: false
        }
      }
    },
    series(){
      return [
        {
          name: 'Wind Conditions',
          type: 'scatter',
          data: [
            {x: 'Light', y: this.loss.Light},
            {x: 'Sf', y: this.loss.Sf},
            {x: 'Df', y: this.loss.Df},
            {x: 'Windy', y: this.loss.Windy},
          ]
        },
        {
          name: 'Regression',
          type: 'line',
          data: [
            {x: 'Light', y: this.regression[0]},
            {x: 'Sf', y: this.regression[1]},
            {x: 'Df', y: this.regression[2]},
            {x: 'Windy', y: this.regression[3]},
          ]
        },
      ]
    }
  },
  watch:{
    start(){
      this.getMetrics()
    },
    end(){
      this.getMetrics()
    },
    boatclass(){
      this.getMetrics()
    },
    user(){
      this.getMetrics()
    }
  }
}
</script>
<style>
  .windchart {
    max-width: 500px;
    text-align: center;
  }
</style>
