<template>
  <div class="tracks-item bg-white padded-container mb-5 p-5">
    <div class="details-container">
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column">
              <a @click="$emit('selected', windfile.id)">
                <h4 class="is-size-4">{{ windfile.original_name }}</h4>
                <p v-if="windfile.start_time && windfile.end_time">
                  {{ windfile.start_time | moment("M/D/YYYY h:mm a") }} —
                  {{ windfile.end_time | moment("M/D/YYYY h:mm a") }} <br>
                </p>
                <p>
                  Uploaded at {{ windfile.time | moment("M/D/YYYY h:mm a") }}
                </p>
                <span class="track-id is-hidden-touch">{{ windfile.id }}</span>
              </a>
              <div class="reports">
                <h4 class="is-size-5">Reports</h4>
                <div v-if="windfile.wind_reports" v-for="report in windfile.wind_reports">
                  <router-link :to="{ name: 'wind-report', params: { report_id: report.id }}">
                    {{ report.name }}: {{ report.start_time | moment("M/D/YYYY h:mm a") }} — {{ report.end_time | moment("M/D/YYYY h:mm a") }}
                  </router-link>
                </div>
                <a @click="createReport">Create Report</a>
              </div>
            </div>
            <div v-if="windfile.static_map" class="column">
              <div class="is-flex is-flex-direction-column is-align-items-flex-start">
                <div class="is-flex is-flex-direction-column is-align-items-center">
                  <img class="static-map" :src="windfile.static_map" />
                </div>
              </div>
            </div>
            <div v-if="error" class="column">
              <div>
                <p class="error">There was an error processing this wind file.</p>
              </div>
            </div>
            <div v-else-if="!loaded" class="column">
              <div>
                <p><i class="fa fa-spinner fa-spin"></i> Wind file processing </p>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="column is-hidden-touch">-->
<!--          <div class="columns">-->
<!--            <div v-if="!loaded" class="column">-->
<!--              <div v-if="!error">-->
<!--                <p><i class="fa fa-spinner fa-spin"></i> {{ message }}</p>-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <p class="error">There was an error processing this track.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-else class="column">-->
<!--              <div class="data-container">-->
<!--                <div class="columns is-hidden-touch" v-if="this.track.metric">-->
<!--                  <div class="column">-->
<!--                    <dl v-if="this.track.metric">-->
<!--                      <dt>DISTANCE</dt>-->
<!--                      <dd>{{ distance }}mi</dd>-->
<!--                      <dt>TWA</dt>-->
<!--                      <dd>{{ track.metric.average_wind_direction }}</dd>-->
<!--                      <dt>DURATION</dt>-->
<!--                      <dd>{{ duration }}</dd>-->
<!--                      <dt>MAX SPEED</dt>-->
<!--                      <dd>{{ track.metric.max_speed }}</dd>-->
<!--                    </dl>-->
<!--                  </div>-->
<!--                  <div class="column">-->
<!--                    <dl>-->
<!--                      <dt>TACKS</dt>-->
<!--                      <dd>{{ track.metric.tacks }}</dd>-->
<!--                      <dt>SETS</dt>-->
<!--                      <dd>{{ track.metric.sets }}</dd>-->
<!--                      <dt>GYBES</dt>-->
<!--                      <dd>{{ track.metric.gybes }}</dd>-->
<!--                      <dt>DOUSES</dt>-->
<!--                      <dd>{{ track.metric.douses }}</dd>-->
<!--                    </dl>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="full_report">-->
<!--                  <router-link-->
<!--                    class="track-download"-->
<!--                    :to="'/reports/' + track.id"-->
<!--                  >-->
<!--                    Full Report-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <b-modal v-model="showCreateReportModal">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Report</p>
      </header>
      <section class="modal-card-body" style="height: 500px">
        <b-field label="Report Name">
          <b-input
            v-model="reportName"
            placeholder="Name for report"
          >
          </b-input>
        </b-field>
        <b-field label="Start time">
          <b-datetimepicker
            v-model="reportStartDate"
            placeholder="Type or select a date..."
            icon="calendar-day"
            locale="en-US"
            editable
            :min-datetime="localForUTC(windfile.start_time)"
            :max-datetime="localForUTC(windfile.end_time)"
          >
          </b-datetimepicker>
        </b-field>
        <b-field label="End time">
          <b-datetimepicker
            v-model="reportEndDate"
            placeholder="Type or select a date..."
            icon="calendar-day"
            locale="en-US"
            editable
            :min-datetime="localForUTC(windfile.start_time)"
            :max-datetime="localForUTC(windfile.end_time)"
          >
          </b-datetimepicker>
        </b-field>
        <p>
          In case wind data was recorded in a different time zone, start and end times will be converted to GMT/UTC automatically.
        </p>
      </section>
      <footer class="modal-card-foot">
        <b-button
          label="Cancel"
          @click="showCreateReportModal = false" />
        <b-button
          :disabled="!(reportName && reportStartDate && reportEndDate)"
          type="is-primary"
          @click="createReportInDB"
        >
          Create and View
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
        </b-button>
      </footer>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services/api/windfiles.js";
import moment from "moment";
import alertHelper from "@/services/helpers/alertHelper";

export default {
  name: "WindFile",
  props: ["windfile"],
  data: function() {
    return {
      fetchCount: 0,
      fetching: false,
      showCreateReportModal: false,
      reportName: null,
      reportStartDate: null,
      reportEndDate: null,
      isLoading: false
    }
  },
  methods: {
    localForUTC(UTC) {
      this.$emit('utc', [UTC, moment(UTC).utc().format(), moment(UTC).utc().local().format()])
      return moment(UTC).utc().local().toDate()
    },
    createReport() {
      this.showCreateReportModal = true;
    },
    createReportInDB() {
      this.isLoading = true;
      const startDateUTC = moment(this.reportStartDate).utc().format();
      const endDateUTC = moment(this.reportEndDate).utc().format();
      api.createWindReport(this.windfile.id, this.reportName, startDateUTC, endDateUTC).then((result) => {
        this.isLoading = false;
        this.$router.push({
          name: "wind-report",
          params: { report_id: result.data.id }
        })
      }).catch((error) => {
        this.isLoading = false;
        alertHelper.alertNotification(this.$store.dispatch, error)
      });
    },
    loadFile() {
      if (!this.loaded && !this.error && (this.fetchCount < 120)) {
        this.fetching = true;
        this.$store.dispatch("windfiles/getWindfile", this.windfile.id);
        this.fetchCount += 1;
        setTimeout(() => {
          this.loadFile();
        }, 5000);
      } else {
        this.fetching = false;
      }
    }
  },
  watch: {
    windfile() {
      if (!this.fetching) {
        this.loadFile(); // poll if processing
      }
    }
  },
  // created() {
  //   this.loadFile() // poll if processing
  // },
  computed: {
    loaded() {
      return this.windfile.processing_status === 3;
    },
    error() {
      return this.windfile.processing_status === 2;
    },
  }
}
</script>

<style>
.tracks-item {
  padding:  20px;
}
dl {
  margin: 0px;
  padding: 0px;
  overflow: auto;
}

dt {
  display: inline-block;
  width: 45%;
  text-decoration: none !important;
  white-space: nowrap;
}

dd {
  width: 55%;
  display: inline-block;
  text-align: right;
}
.full_report {
  width: 100%;
  display: flex;
  justify-content: center;
}

.data-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 150px;
}
.details-container {
  margin-right: 4rem;
}
</style>
<style lang="sass">
.tracks-item
  position: relative

  &:hover
    box-shadow: 0 0 25px 0 rgba(93, 148, 218, .1)

  .validation-error-text
    padding-top: 6px
    height: 14px
    font-size: 10px
    color: $coral

  a
    color: $font-color
    text-decoration: none

    &:hover
      text-decoration: none

  .reports
    margin-top: 1em
    h4
      color: $font-color
    a
      color: $primary

      &:hover
        color: $primary-hover

  .title
    font-size: 18px
    margin: 10px 0px

  .subtitle
    font-size: 16px
    margin: 1px

  .track-menu
    position: absolute
    right: 15px
    top: 10px
    font-size: 14px

    .dropdown-container
      width: 200px

  .track-download
    cursor: pointer
    &:hover
      color: #6D5CAE

  .open-journal
    cursor: pointer
    position: absolute
    right: 20px
    top: 45px

    &:hover
      color: #6D5CAE

  .sample-warning
    cursor: pointer
    color: $warning
    position: absolute
    right: 20px
    top: 85px
    z-index: 1

    &:hover
      color: $warning-hover

  .warning-container
    max-width: 600px
    a
      text-decoration: underline
      color: revert

  .exportCSV
    cursor: pointer

    &:hover
      color: #6D5CAE

  .track-title-container
    display: flex
    justify-content: space-between
    align-items: center

  .track-time
    opacity: 0.5
    font-size: 10px

  .track-id
    font-size: 100px
    opacity: .5
    position: absolute
    right: -15px
    bottom: -50px
    color: #ccc
    margin: 0
    padding: 0
    z-index: 0
    font-weight: 600
    display: block
    line-height: 160px

  .fa-stack[data-count]:after
    position: absolute
    right: 0%
    top: 1%
    content:  attr(data-count)
    font-size: 50%
    padding: .6em
    border-radius: 999px
    line-height: .75em
    color:  white
    background: rgba(255,0,0,.85)
    text-align: center
    // min-width: 2em
    font-weight: bold
</style>