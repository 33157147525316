<template>
  <layout>
    <div class="container" v-if="team">
      <div class="columns">
        <div class="column">
          <h1 class="is-size-3">{{ team.name }}</h1>
          <b-button type="is-danger" @click="leaveTeam" icon-left="sign-out-alt"
            >Leave team</b-button
          >

          <h1 class="is-size-5">Coaches</h1>
          <div class="columns is-multiline">
            <div
              class="column is-one-third"
              v-for="coach in team.coaches"
              v-bind:key="coach.email"
            >
              <AthleteCard :athlete="coach">
                <template v-slot:footer v-if="isCoach">
                  <footer class="card-footer">
                    <a
                      class="button is-link is-inverted card-footer-item"
                      :disabled="team.coaches.length < 2"
                      @click="checkUpdateRole(coach, 'ATHLETE')"
                      >Demote</a
                    >
                    <a
                      class="button is-link card-footer-item is-inverted"
                      :disabled="team.coaches.length < 2"
                      @click="removeAthlete(coach)"
                      >Remove</a
                    >
                  </footer>
                </template>
              </AthleteCard>
            </div>
          </div>

          <h1 class="is-size-5">Athletes</h1>
          <b-button icon-left="user-plus" @click="promptAthlete" v-if="isCoach">
            Add
          </b-button>
          <div class="columns is-multiline">
            <div
              class="column is-one-third"
              v-for="athlete in team.athletes"
              v-bind:key="athlete.email"
            >
              <AthleteCard :athlete="athlete">
                <template v-slot:footer v-if="isCoach">
                  <footer class="card-footer">
                    <a
                      class="card-footer-item"
                      @click="updateRole(athlete, 'COACH')"
                      >Promote</a
                    >
                    <a class="card-footer-item" @click="removeAthlete(athlete)"
                      >Remove</a
                    >
                  </footer>
                </template>
              </AthleteCard>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter" v-if="isCoach">
          <h1 class="is-size-3">Permissions</h1>
          <p>Who can see my tracks on this team?</p>
          <div class="block">
            <b-radio
              v-model="team.membership.permissions"
              @input="changePermission"
              name="name"
              native-value="ALL"
            >
              All Teammates
            </b-radio>
            <b-radio
              v-model="team.membership.permissions"
              @input="changePermission"
              name="name"
              native-value="COACH_ONLY"
            >
              Coaches Only
            </b-radio>
          </div>
          <h1 class="is-size-3">Update Team</h1>
          <TeamForm
            :initial="team"
            @submitted="update"
            ref="teamform"
          ></TeamForm>
          <b-button type="is-danger" @click="deleteTeam" icon-left="trash"
            >Delete team</b-button
          >
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import TeamForm from "@/views/teams/TeamForm"
import layout from "@/views/layouts/Default"
import AthleteCard from "@/views/teams/AthleteCard"
import { mapGetters } from "vuex"
export default {
  props: ["id"],
  components: { layout, TeamForm, AthleteCard },
  data() {
    return {}
  },
  created() {
    this.$store.dispatch("teams/fetchTeam", { id: this.id })
  },
  methods: {
    update(formData) {
      this.$store.dispatch("teams/updateTeam", {
        id: this.id,
        formData: formData,
      })
    },
    promptAthlete() {
      this.$buefy.dialog.prompt({
        message: "Enter email address",
        inputAttrs: {
          placeholder: "example@example.com",
          type: "email",
        },
        trapFocus: true,
        onConfirm: (email) => this.addAthlete(email),
      })
    },
    addAthlete(email) {
      this.$store.dispatch("teams/addMember", { id: this.id, email: email })
    },
    removeAthlete(athlete) {
      this.$store.dispatch("teams/removeMember", {
        id: this.id,
        email: athlete.email,
      })
    },
    checkUpdateRole(athlete, role) {
      if (athlete.email === this.user.email) {
        this.confirmDemoteSelf(athlete, role)
      } else {
        this.updateRole(athlete, role)
      }
    },
    confirmDemoteSelf(athlete, role) {
      this.$buefy.dialog.confirm({
        title: "Demote yourself?",
        message:
          "Are you sure you want to demote yourself? You will no longer be able to make changes to this team.",
        confirmText: "Demote",
        type: "is-danger",
        onConfirm: () => {
          this.updateRole(athlete, role)
        },
      })
    },
    updateRole(athlete, role) {
      this.$store.dispatch("teams/updateRole", {
        id: this.id,
        email: athlete.email,
        role: role,
      })
    },
    changePermission() {
      this.$store.dispatch("teams/changePermission", {
        id: this.id,
        permission: this.team.membership.permissions,
      })
    },
    deleteTeam() {
      this.$buefy.dialog.confirm({
        title: "Deleting team",
        message:
          "Are you sure you want to <b>delete</b> this team? This action cannot be undone.",
        confirmText: "Delete Team",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch("teams/deleteTeam", { id: this.id })
          this.$router.replace({ name: "teams" })
        },
      })
    },
    leaveTeam() {
      this.$buefy.dialog.confirm({
        title: "Leave team",
        message: "Are you sure you want to leave this team?",
        confirmText: "Leave Team",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch("teams/leaveTeam", { id: this.id })
          this.$router.replace({ name: "teams" })
        },
      })
    },
  },
  computed: {
    ...mapGetters({
      team: "teams/getTeam",
      user: "auth/user",
    }),
    isCoach() {
      return (
        this.team.coaches.filter((coach) => coach.email === this.user.email)
          .length > 0
      )
    },
  },
}
</script>
