<template>
  <div>
    <h1 class="is-size-3">New Team</h1>
    <TeamForm @submitted="createTeam" ref="teamForm"></TeamForm>
  </div>
</template>
<script>
import TeamForm from "@/views/teams/TeamForm"
export default {
  name: "CreateTeam",
  components: { TeamForm },
  data() {
    return {}
  },
  methods: {
    createTeam(formData) {
      this.$store.dispatch("teams/createTeam", { formData: formData })
      this.$refs.teamForm.reset()
    },
  },
}
</script>
