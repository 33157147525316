<template>
  <div class="details-container">
    <div class="columns">
      <div class="column">
        <div class="columns">
          <div class="column">
            <router-link :to="{ name: 'track-details', params: { id: track.id } }">
            <h4 v-if="track.full_name" class="is-size-4">{{ track.full_name }}</h4>
            <p>
              {{ track.start_time | moment("timezone", track.tz, "M/D/YYYY - h:mm a z") }}<br />
              {{ track.location }}<br />
              Visibility: {{ track.permissions }}<br />
              {{ track.boat_name }}
              <b-tooltip v-if="track.wind_file"
                         :label="`File ${track.wind_file}`"
                         position="is-right">
                <b-icon icon="wind"/>
              </b-tooltip>
            </p>
            <span class="track-id is-hidden-touch">{{ track.id }}</span>
            </router-link>
          </div>
          <div v-if="loaded" class="column">
            <div class="is-flex is-flex-direction-column is-align-items-flex-start">
              <div class="is-flex is-flex-direction-column is-align-items-center">
                <img class="static-map" :src="map" />
                <router-link
                  v-if="!journalView"
                  class="track-download is-hidden-desktop mt-4"
                  :to="'/reports/' + track.id"
                >
                Full Report
                </router-link>
              </div>
            </div>
          </div>
          <div v-else class="column is-hidden-desktop">
            <div v-if="!error">
              <p><i class="fa fa-spinner fa-spin"></i> {{ message }}</p>
            </div>
            <div v-else>
              <p class="error">There was an error processing this track.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-hidden-touch">
        <div class="columns">
          <div v-if="!loaded" class="column">
            <div v-if="!error">
              <p><i class="fa fa-spinner fa-spin"></i> {{ message }}</p>
            </div>
            <div v-else>
              <p class="error">There was an error processing this track.</p>
            </div>
          </div>
          <div v-else class="column">
            <div class="data-container">
              <div class="columns is-hidden-touch" v-if="this.track.metric">
                <div class="column">
                  <dl v-if="this.track.metric">
                    <dt>DISTANCE</dt>
                    <dd>{{ distance }}mi</dd>
                    <div v-if="track.twd_avg">
                      <dt>TWD</dt>
                      <dd>{{ track.twd_avg.toFixed(3) }}</dd>
                    </div>
                    <div v-else>
                      <dt>ETWD</dt>
                      <dd>{{ track.metric.average_wind_direction }}</dd>
                    </div>
                    <dt>DURATION</dt>
                    <dd>{{ duration }}</dd>
                    <dt>MAX SPEED</dt>
                    <dd>{{ track.metric.max_speed }}</dd>
                  </dl>
                </div>
                <div class="column">
                  <dl>
                    <dt>TACKS</dt>
                    <dd>{{ track.metric.tacks }}</dd>
                    <dt>SETS</dt>
                    <dd>{{ track.metric.sets }}</dd>
                    <dt>GYBES</dt>
                    <dd>{{ track.metric.gybes }}</dd>
                    <dt>DOUSES</dt>
                    <dd>{{ track.metric.douses }}</dd>
                  </dl>
                </div>
              </div>
              <div class="full_report" v-if="!journalView">
                <router-link
                  class="track-download"
                  :to="'/reports/' + track.id"
                >
                Full Report
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tracks from "@/services/api/tracks.js";
export default {
  name: "TrackListDetails",
  props: ["track", "staticMap", "journalView"],
  data() {
    return {
      user: null,
      error: false,
      message: "",
      fetchCount: 0,
      loaded: true,
    };
  },
  created() {
    this.user = this.$store.getters["auth/user"];
  },
  mounted() {
    if (this.track.processing_status != 3 || !this.track.metric) {
      this.loaded = false;
      this.loadTrack();
    }
  },
  methods: {
    loadTrack() {
      tracks.getTrack(this.track.id, true).then((result) => {
        if (result.data.processing_status == 3) {
          this.$store.dispatch('tracks/updateTrackInStore', result.data)
          this.loaded = true;
        } else {
          this.message = result.data.detail;
          if (this.message === "Track processing has ended with errors.") {
            this.error = true;
          } else if (this.fetchCount > 120) {
            // show this after 10 minutes
            this.message = "Could not retrieve data. Please check back later.";
          } else {
            this.fetchCount++;
            setTimeout(() => {
              this.loadTrack();
            }, 5000);
          }
        }
      });
    },
  },
  computed: {
    distance() {
      return this.track.metric.distance
        ? (this.track.metric.distance * 0.0006213712).toFixed(2)
        : 0;
    },
    duration() {
      if (this.track.metric.duration) {
        let wallTime = new Date(null);
        wallTime.setSeconds(this.track.metric.duration);
        return wallTime.toISOString().substr(11, 8);
      } else {
        return "0:00";
      }
    },
    map() {
      if (this.track == null && this.staticMap != null && this.isVisible) {
        return this.staticMap;
      } else if (this.track) {
        return this.track.static_map;
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="sass">
.static-map
  max-height: 150px
</style>
<style>
dl {
  margin: 0px;
  padding: 0px;
  overflow: auto;
}

dt {
  display: inline-block;
  width: 45%;
  text-decoration: none !important;
  white-space: nowrap;
}

dd {
  width: 55%;
  display: inline-block;
  text-align: right;
}
.full_report {
  width: 100%;
  display: flex;
  justify-content: center;
}

.data-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 150px;
}
.details-container {
  margin-right: 4rem;
}
</style>
