import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store/store"
import Upload from "../views/tracks/Upload.vue"
import Tracks from "../views/tracks/Tracks.vue"
import RaceMap from "../views/tracks/RaceMap.vue"
import Map from "../views/tracks/Map.vue"
import Login from "../views/auth/Login"
import Registration from "../views/auth/Registration"
import ForgotPassword from "../views/auth/ForgotPassword"
import ResetPassword from "../views/auth/ResetPassword"
import Profile from "../views/account/Profile"
import Garmin from "../views/account/Garmin"
// import Payment from "../views/account/Payment"
import Beta from "../views/account/Beta"
import Dashboard from "../views/dashboard"
import UserJournal from '../views/journal/UserJournal'
import Reports from "../views/dashboard/Reports"
import TrackReport from "../views/tracks/TrackReport"
import Teams from "../views/teams/"
import TeamDetail from "../views/teams/TeamDetail"
import Home from "../views/layouts/Home"

import PrivacyPolicy from "../views/static/PrivacyPolicy"
import TermsAndConditions from "../views/static/TermsAndConditions"
import CookiePolicy from "../views/static/CookiePolicy"
import StraightLineReport from "@/views/tracks/StraightLineReport";
import Wind from "@/views/wind/Wind";
import WindReport from "@/views/wind/WindReport";

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next()
    return
  }
  next("/")
}

const ifAuthenticated = (to, from, next) => {
  if (from.name === "race-details") {
    store.dispatch("races/clearRace")
  }
  if (store.getters["auth/isAuthenticated"]) {
    next()
    return
  }
  next("/login")
}

const routes = [
  {
    path: "/",
    name: "root",
    redirect: {name: 'home'}
  },
  {
    path: '/upload',
    name: "upload",
    component: Upload,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/wind',
    name: "wind",
    component: Wind,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/wind-report/:report_id',
    name: "wind-report",
    component: WindReport,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/register",
    name: "register",
    component: Registration,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/forgot",
    name: "forgot",
    component: ForgotPassword,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/reset/:uuid",
    name: "reset",
    component: ResetPassword,
    beforeEnter: ifNotAuthenticated,
    props: true,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: ifAuthenticated,
    redirect: '/dashboard/journal',
    children: [
      {
        path: 'journal',
        name: 'dashboard-journal',
        component: UserJournal
      },
      {
        path: 'reports',
        name: 'dashboard-reports',
        component: Reports
      }
    ]
  },
  {
    path: '/dashboard/reports',
    component: Reports,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/reports/:track_id',
    component: TrackReport,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/straight-analysis/:id',
    component: StraightLineReport,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/account",
    name: "account",
    component: Profile,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/account/garmin",
    name: "garmin",
    component: Garmin,
    beforeEnter: ifAuthenticated,
  },
  /*{
    path: "/account/plan",
    name: "plan",
    component: Payment,
    beforeEnter: ifAuthenticated,
  },*/
  {
    path: "/account/beta",
    name: "beta",
    component: Beta,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/tracks",
    name: "tracks",
    redirect: {'name': 'home'}
  },
  {
    path: "/tracks/:id",
    name: "track-details",
    component: Map,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/races/:id",
    name: "race-details",
    component: RaceMap,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/teams",
    name: "teams",
    component: Teams,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/teams/:id",
    name: "teams-detail",
    component: TeamDetail,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    component: CookiePolicy,
  },
  {
    path: "*",
    redirect: { name: "login" },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
